import { FC, createElement, useState } from 'react';
import { useHttpQuery } from 'packages/http-client/react';
import { createLogActionRequest } from 'apps/acp/packages/webapi';
import { useAdaChatButtonTheme } from './ada-chatbot.theme';
import { AdaCustomChatButton } from 'packages/ada-chatbot/react';

export const AdaLogWebAction = () => {
  useHttpQuery(
    createLogActionRequest({
      actionLog: 'ADA_CHATBOT_CLICKED'
    })
  );

  return null;
};

export const AdaChatButton: FC<{ authed?: boolean }> = ({ authed = false }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const { leftIcon, rightIcon } = useAdaChatButtonTheme();

  return window.adaEmbed ? (
    <div onClick={() => setClicked(true)}>
      {authed && clicked && <AdaLogWebAction />}
      <AdaCustomChatButton
        leftIconColor={leftIcon}
        rightIconColor={rightIcon}
      />
    </div>
  ) : null;
};
