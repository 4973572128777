import { DeepPartial, createUseThemeHook } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    adaChatButton: DeepPartial<AdaChatButtonTheme>;
  }
}

export interface AdaChatButtonTheme {
  leftIcon: string;
  rightIcon: string;
}

export const [
  AdaChatButtonThemeProvider,
  useAdaChatButtonTheme
] = createUseThemeHook(
  'adaChatButton',
  (global): AdaChatButtonTheme => ({
    leftIcon: global.special.color500,
    rightIcon: global.special.color200
  })
);
